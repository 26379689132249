    /* faq-tab content - closed */
    .faq-content {
    max-height: 0;
    -webkit-transition: max-height .35s;
    -o-transition: max-height .35s;
    transition: max-height .35s;
    }
    /* :checked - resize to full height */
    .faq-tab input:checked ~ .faq-content {
    max-height: 100vh;
    }
    /* Label formatting when open */
    .faq-tab input:checked + label{
    /*@apply text-xl p-5 border-l-2 border-indigo-500 bg-gray-100 text-indigo*/
    font-size: 1.25rem; /*.text-xl*/
    padding: 1.25rem; /*.p-5*/
    background-color: #f8fafc; /*.bg-gray-100 */
    color: #004aad; /*.text-indigo*/
    }
    /* Icon */
    .faq-tab label::after {
    float:right;
    right: 0;
    top: 0;
    display: block;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5;
    font-size: 1.25rem;
    text-align: center;
    -webkit-transition: all .35s;
    -o-transition: all .35s;
    transition: all .35s;
    }
    /* Icon formatting - closed */
    .faq-tab input[type=checkbox] + label::after {
    content: "+";
    font-weight:bold; /*.font-bold*/
    border-width: 1px; /*.border*/
    border-radius: 9999px; /*.rounded-full */
    border-color: #b8c2cc; /*.border-grey*/
    }
    .faq-tab input[type=radio] + label::after {
    content: "\25BE";
    font-weight:bold; /*.font-bold*/
    border-width: 1px; /*.border*/
    border-radius: 9999px; /*.rounded-full */
    border-color: #b8c2cc; /*.border-grey*/
    }
    /* Icon formatting - open */
    .faq-tab input[type=checkbox]:checked + label::after {
    transform: rotate(315deg);
    background-color: #004aad; /*.bg-indigo*/
    color: #f8fafc; /*.text-grey-lightest*/
    }
    .faq-tab input[type=radio]:checked + label::after {
    transform: rotateX(180deg);
    background-color: #004aad; /*.bg-indigo*/
    color: #f8fafc; /*.text-grey-lightest*/
    }

    .faq-tab label{
        background-color: #FFFFFF;;
    }