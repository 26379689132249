.education-qualification{
    overflow: hidden;
    overflow-x: auto;
}

@media print{

    /* body{
        -webkit-transform: scale(1);  Saf3.1+, Chrome 
          -moz-transform: scale(1);  FF3.5+ 
          -ms-transform: scale(1);  IE9 
          -o-transform: scale(1);  Opera 10.5+ 
          transform: scale(1);
          margin: 0;
    } */

    body{
        width: 1000px;
    }

    body .grid-cols-2 {
        width: 100%;
    }

    .education-qualification{
        width: 100%;
        overflow: unset;
    }

    
    
    table.table{
        width: 100%;
        overflow: visible;
    }

    table thead tr th, table tbody tr td{
        padding: 0.2rem !important;
    }

}