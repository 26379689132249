.footer-section .footer-logo img{
    width: 100px;
    margin: 0 auto;
}

ul.footer-links li{
    padding: 0.5rem;
}

ul.footer-links li a:hover{
    text-decoration: underline;
}