.tabs-nav .triangle{
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 20px solid #ffffff;
    margin: 0 auto;
    display: none;
}

.tabs-nav .active .triangle{
    display: block;
}