.user-img-wrap{
    background-color: #ffffff;
    padding: 0.5rem;
}

.user-img-wrap img{
    width: 30px;
}

.conversation-item .panel {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }