header.fixed-bg nav ul li, header.white-bg nav ul li, header.fixed-bg .logo-text, header.white-bg .logo-text{
    color: #000000;
}

.logo-text{
    color: #ffffff;
    font-size: 0.9rem;
}

header.main-nav{
    padding: 0 1rem;
}

#resend-btn:disabled{
    cursor: not-allowed !important;
}

header.fixed-bg{
    position: fixed;
}

header.fixed-bg, header.white-bg{
    background-color: #ffffff;
    padding: 0 1rem;
}

label.pointer-cursor{
    color: #ffffff;
}

header.fixed-bg label.pointer-cursor, header.white-bg label.pointer-cursor{
    color: #000000;
}

#menu-toggle:checked + #menu nav ul li {
    color: #000000;
}

#menu-toggle:checked + header.main-nav {
    background-color: #ffffff;
}

.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {background-color: #f3f3f3;}

  .dropdown:hover .dropdown-content {display: block;}

@media(max-width: 768px){
    label.pointer-cursor{
        color: #000000;
    }
    .logo-text{
        color: #000000;
    }
}