.section-nav ul li{
    padding: 0.5rem 1rem;
}

@media (max-width:576px){
    a.course-apply-btn{
        width: 100%;
        text-align: center;
    }

    .table-responsive{
        overflow-x: auto;
    }
}