.page-banner{
    background-size: cover;
    background-position: center center;
    height: 300px;
    position: relative;
}

.banner-content{
    position: absolute;
    top: 40%;
    width: 100%;
    font-weight: 400;
    color: #ffffff;
    text-transform: uppercase;
}