.how-to-apply-body .col-span-1 img{
    width: 100px;
    margin: 0 auto;
}

@media(max-width: 576px){

.hta-wrapper{
    overflow-x: auto;
}

}