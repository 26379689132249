.important-link-item-wrap .link-img img{
    width: 40px;
    margin: 0 0.5rem;
}

.important-link-item-wrap{
    border: 1px solid #f2f2f2;
}

.important-link-item-wrap .link-text{
    margin: 0 auto;
}

.important-link-item-wrap .link-icon{
    font-size: 2rem;
}