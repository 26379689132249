.message-item img{
    width: 100px;
}

.msg-sidebar-ul li{
    border: 1px solid transparent;
}

.msg-sidebar-ul li.active, .msg-sidebar-ul li:hover {
    background-color: #ffffff;
    border: 1px solid #e4e1e1;
}