*, *::after, *::before {
    box-sizing: inherit;
}

:root {
  --dark-blue: #004aad;
  --light-blue: #38b6ff;
  --yellow: #ffbd59;
  --font-yellow-bg: #043980;
  --footer-bg: #043980;
  --bg-light-blue: #38b6ff;
  --light-blue-2: #F5FBFF;
}

html{
    box-sizing: border-box;
    height: 100%;
    scroll-behavior: smooth;
    overflow-x: hidden;
    width: 100vw;
}

/* Text Colors */
.text-dark-blue{
    color: var(--dark-blue);
}

.text-light-blue{
    color: var(--light-blue);
}

/* Background Colors */
.bg-light-blue{
    background-color: var(--light-blue-2);
}

.bg-dark-blue{
    background-color: var(--dark-blue);
}

.bg-yellow{
    background-color: var(--yellow);
    color: var(--font-yellow-bg);
}

/* Important Link Item */
.important-link-item-wrap{
    border-left: 5px solid var(--dark-blue);
}

/* Tabs Section */
.awards-tabs-nav button .tab-border{
    border-bottom: 4px transparent;
}

.awards-tabs-nav button:focus{
    outline:0;
}

.awards-tabs-nav button.active{
    border-bottom: 4px solid var(--light-blue);
}

.tabs-content .tabcontent, .tabs-section-one{
    display: none;
}

.tabs-content .tabcontent.active, .tabs-section-one.active{
    display: block;
}

/* Image Overlay */
.image-overlay {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

/* RGU Button*/
.rgu-btn-light{
    background-color: var(--light-blue);
    color: #FFFFFF;
    padding: 0.8rem 1.6rem;
    border-radius: 0.25rem;
}

.rgu-btn-light:disabled{
  cursor: not-allowed;
}

.rgu-btn-dark{
    background-color: var(--dark-blue);
    color: #FFFFFF;
    padding: 0.8rem 1.6rem;
    border-radius: 0.25rem;
}


/* Page Breadcrumb */
ul.breadcrumb-list li{
    display: inline-block;
    margin-right: 0.3rem;
    color: #a6a6a6;
  }
  
  ul.breadcrumb-list li:nth-child(even) {
    margin-bottom: -0.2rem;
  }

/* Section Nav */
.section-nav ul li.active{
    border-bottom: 5px solid var(--light-blue);
}

/* Paragraph */
p.justify{
    text-align: justify;
    margin-bottom: 0.5rem;
}

/* INline Icon*/
span.inline-icon{
    display: inline-block;
    margin-bottom: -0.1rem;
    margin-right: 0.5rem;
}

/* List Style */

ul.dot-ul{
    list-style-type: disc;
    margin-left: 5%;
}

/* navbar toggle */

#menu-toggle:checked + #menu {
    display: block;
    background-color: #FFFFFF;
}


/* Custom Radio Button */

/* The container */
.custom-radio-btn .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .custom-radio-btn .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .custom-radio-btn .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .custom-radio-btn .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .custom-radio-btn .container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .custom-radio-btn .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .custom-radio-btn .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .custom-radio-btn .container .checkmark:after {
       top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  }

  .custom-radio-btn{
      padding-top: 0.5rem;
  }

  /* The container */
.custom-checkbox .container {
    display: block;
    position: relative;
    padding-left: 2rem;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .custom-checkbox .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .custom-checkbox .checkmark {
    position: absolute;
    top: 0.15rem;
    left: 0;
    height: 23px;
    width: 23px;
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #03a9f4;
  }
  
  /* On mouse-over, add a grey background color */
  .custom-checkbox .container:hover input ~ .checkmark {
    background-color: #f2f2f2;
  }
  
  /* When the checkbox is checked, add a blue background */
  .custom-checkbox .container input:checked ~ .checkmark {
    background-color: #03a9f4;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .custom-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .custom-checkbox .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .custom-checkbox .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  /****** Selected Image Sizes **********/

  #selected-signature img, #selected-photo img{
    width: 150px;
  }